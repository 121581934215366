<!-- src/views/Datenschutz.vue -->
<template>
  <div>
      <header class="app navigation" style="flex: 0; background-color: var(--msag-color); padding: 10px; position: fixed; width: 100%">
      <v-container>
        <v-row justify="space-between" style="align-items: center; padding-bottom: 5px;">
          <div style="margin-right:10px;">
            <a href="/">
              <v-row justify="space-between" style="align-items: center;">
                <v-col style="flex:0;">
                  <div style="background-color:white; padding: 10px; border-radius: 10px; height: 50px; width: 50px;">
                    <img src="@/assets/Maklersprechstunde-Logo.png" alt="Square Image" style="width: 30px; height: 30px;"/>
                  </div>
                </v-col>
                <v-col style="flex:1; padding: 0px; margin: 0px;">
                  <h1 style="color: white; text-align: left;">Portalpreise.com</h1>
                </v-col>
              </v-row>
            </a>
          </div>
        </v-row>
      </v-container>
    </header>

    <v-container>
      <h1 style="margin-top: 100px;">Datenschutzerklärung</h1>
      <p>
      Wir freuen uns, dass Sie unsere Website besuchen. Der Schutz und die Sicherheit Ihrer persönlichen Informationen bei der Nutzung unserer Website ist für uns sehr wichtig. Wir möchten Sie daher an dieser Stelle darüber informieren, welche Ihrer personenbezogenen Daten wir beim Besuch unserer Website erfassen und für welche Zwecke diese verwendet werden.
      </p>
      <p>
      Diese Datenschutzerklärung gilt für das Internetangebot der portalpreise, welches unter der Domain portalpreise.com sowie den verschiedenen Subdomains (www.portalpreise.com) erreichbar ist.
      </p>
      <p>
      Wer ist verantwortlich und wie erreiche ich Sie?
      </p>
      <p>
      Verantwortlicher für die Verarbeitung personenbezogener Daten im Sinne der EU-Datenschutz-Grundverordnung (DSGVO)
      </p>
      <p>
      <b>Makler Service AG</b>, Baarerstrasse 133, 6300 Zug
      <br>
      E-Mail: office@maklersprechstunde.com, Internet: www.immobilien-makler-akademie.com
      <br>
      Tel: +41 415520292
      <br>
      <b>Vertretungsberechtigte Personen:</b>
      <br>
      – Verwaltungsrat Georg Ortner, – Verwaltungsrat Roland Beeler
      </p>
      <p>
      <b>Handelsregistereintrag:</b>
      <br>
      Registergericht: Handelsregister des Kanton Zug
      <br>
      UID (Unternehmensidentifikationsnummer): CHE-491.523.825
      <br>
      Mehrwertsteuernummer CHE-491.523.825 MWST
      <br>
      Verantwortlich für den Inhalt: Georg Ortner
      </p>
      <p>
      Ein Datenschutzbeauftragter muss nicht benannt werden. 
      </p>
      <p>
      Worum geht es?
      </p>
      <p>
      Diese Datenschutzerklärung erfüllt die gesetzlichen Anforderungen an die Transparenz bei der Verarbeitung personenbezogener Daten. Dies sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Hierzu gehören beispielsweise Informationen wie Ihr Name, Ihr Alter, Ihre Anschrift, Ihre Telefonnummer, Ihr Geburtsdatum, Ihre E-Mail-Adresse, Ihre IP-Adresse oder das Nutzerverhalten beim Besuch einer Website. Informationen, bei denen wir keinen (oder nur mit einem unverhältnismäßigen Aufwand) Bezug zu Ihrer Person herstellen können, z.B. durch Anonymisierung, sind keine personenbezogenen Daten. Die Verarbeitung von personenbezogenen Daten (bspw. das Erheben, das Abfragen, die Verwendung, die Speicherung oder die Übermittlung) bedarf immer einer gesetzlichen Grundlage und eines definierten Zwecks.
      </p>
      <p>
      Gespeicherte personenbezogene Daten werden gelöscht, sobald der Zweck der Verarbeitung erreicht wurde und es keine rechtmäßigen Gründe für eine weitere Aufbewahrung der Daten gibt. Wir informieren Sie in den einzelnen Verarbeitungsvorgängen über die konkreten Speicherfristen bzw. Kriterien für die Speicherung. Unabhängig davon, speichern wir Ihre personenbezogenen in Einzelfällen zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen und bei Vorliegen gesetzlicher Aufbewahrungspflichten.
      </p>
      <p>
      Wer bekommt meine Daten?
      </p>
      <p>
      Wir geben Ihre personenbezogenen Daten, die wir auf unserer Website verarbeiten nur dann an Dritte weiter, wenn dies für die Erfüllung der Zwecke erforderlich ist und im Einzelfall von der Rechtsgrundlage (z.B. Einwilligung oder Wahrung berechtigter Interessen) erfasst ist. Darüber hinaus geben wir im Einzelfall personenbezogene Daten an Dritte weiter, wenn dies der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. Mögliche Empfänger können dann z.B. Strafverfolgungsbehörden, Rechtsanwälte, Wirtschaftsprüfer, Gerichte usw. sein.
      </p>
      <p>
      Soweit wir für den Betrieb unserer Website Dienstleister einsetzen, die im Rahmen einer Auftragsverarbeitung in unserem Auftrag personenbezogene Daten gem. Art. 28 DSGVO verarbeiten, können diese Empfänger Ihrer personenbezogenen Daten sein. Nähere Informationen zum Einsatz von Auftragsverarbeitern sowie von Webdiensten erhalten Sie in der Übersicht der einzelnen Verarbeitungsvorgänge.
      </p>
      <p>
      Setzen Sie Cookies ein?
      </p>
      <p>
      Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer Internetseiten von uns an den Browser Ihres Endgeräts gesendet und dort gespeichert werden. Alternativ zum Einsatz von Cookies können Informationen auch im lokalen Speicher (local storage) Ihres Browsers gespeichert werden. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies bzw. local storage nicht angeboten werden (technisch notwendige Cookies). Andere Cookies ermöglichen uns hingegen verschiedene Analysen, sodass wir beispielsweise in der Lage sind, den von Ihnen verwendeten Browser bei einem erneuten Besuch unserer Webseite wiederzuerkennen und verschiedene Informationen an uns zu übermitteln (nicht notwendige Cookies). Mithilfe von Cookies können wir unter anderem unser Internetangebot für Sie nutzerfreundlicher und effektiver gestalten, indem wir etwa Ihre Nutzung unserer Website nachvollziehen und Ihre bevorzugten Einstellungen (bspw. Länder- und Spracheneinstellungen) feststellen. Sofern Dritte über Cookies Informationen verarbeiten, erheben diese die Informationen direkt über Ihren Browser. Cookies richten auf Ihrem Endgerät keinen Schaden an. Sie können keine Programme ausführen und keine Viren enthalten.
      </p>
      <p>
      Über die jeweiligen Dienste, für die wir Cookies einsetzen, informieren wir in den einzelnen Verarbeitungsvorgängen. Ausführliche Informationen zu den eingesetzten Cookies finden Sie in den Cookie-Einstellungen oder im Consent Manager dieser Website.
      </p>
      <p>
      Welche Rechte habe ich?
      </p>
      <p>
      Unter den Voraussetzungen der gesetzlichen Vorschriften der Datenschutz-Grundverordnung (DSGVO) haben Sie als betroffene Person folgende Rechte:
      </p>
      <p>
      * Auskunft gem. Art. 15 DSGVO über die zu Ihrer Person gespeicherten Daten in Form von aussagekräftigen Informationen zu den Einzelheiten der Verarbeitung sowie eine Kopie Ihrer Daten;
      <br>
      * Berichtigung gem. Art. 16 DSGVO von unrichtigen oder unvollständigen Daten, die bei uns gespeichert sind;
      <br>
      * Löschung gem. Art. 17 DSGVO der bei uns gespeicherten Daten, soweit die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
      <br>
      * Einschränkung der Verarbeitung gem. Art. 18 DSGVO, soweit die Richtigkeit der Daten bestritten wird, die Verarbeitung unrechtmäßig ist, wir die Daten nicht mehr benötigen und Sie deren Löschung ablehnen, weil Sie diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie Widerspruch gegen die Verarbeitung gem. Art. 21 DSGVO erhoben haben.
      <br>
      * Datenübertragbarkeit gem. Art. 20 DSGVO, soweit Sie uns personenbezogene Daten im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder auf Grundlage eines Vertrages gem. Art. 6 Abs. 1 lit. b DSGVO bereitgestellt haben und diese durch uns mithilfe automatisierter Verfahren verarbeitet wurden. Sie erhalten Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format bzw. wir übermitteln die Daten direkt an einen anderen Verantwortlichen, soweit dies technisch machbar ist.
      <br>
      * Widerspruch gem. Art. 21 DSGVO gegen die Verarbeitung Ihrer personenbezogenen Daten, soweit diese auf Grundlage des Art. 6 Abs. 1 lit. e, f DSGVO erfolgt und dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Das Recht auf Widerspruch besteht nicht, wenn überwiegende, zwingende schutzwürdige Gründe für die Verarbeitung nachgewiesen werden oder die Verarbeitung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erfolgt. Soweit das Recht auf Widerspruch bei einzelnen Verarbeitungsvorgängen nicht besteht, ist dies dort angegeben.
      <br>
      * Widerruf gem. Art. 7 Abs. 3 DSGVO Ihrer erteilten Einwilligung mit Wirkung für die Zukunft.
      <br>
      * Beschwerde gem. Art. 77 DSGVO bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, die Verarbeitung Ihrer personenbezogenen Daten verstößt gegen die DSGVO. In der Regel können Sie sich an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
      </p>
      <p>
      Wie werden meine Daten im Einzelnen verarbeitet?
      </p>
      <p>
      Nachfolgend informieren wir Sie über die einzelnen Verarbeitungsvorgänge, den Umfang und den Zweck der Datenverarbeitung, die Rechtsgrundlage, die Pflicht zur Bereitstellung Ihrer Daten und die jeweilige Speicherdauer. Eine automatisierte Entscheidung im Einzelfall, einschließlich Profiling findet nicht statt.
      </p>
      <p>
      Bereitstellung der Website
      </p>
      <p>
      Art und Umfang der Verarbeitung
      </p>
      <p>
      Bei Aufruf und Nutzung unserer Website erheben wir die personenbezogenen Daten, die Ihr Browser automatisch an unseren Server übermittelt. Die folgenden Informationen werden temporär in einem sog. Logfile gespeichert:
      </p>
      <p>
      * IP-Adresse des anfragenden Rechners
      <br>
      * Datum und Uhrzeit des Zugriffs
      <br>
      * Name und URL der abgerufenen Datei
      <br>
      * Website, von der aus der Zugriff erfolgt (Referrer-URL)
      <br>
      * Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners, sowie der Name Ihres Access-Providers
      </p>
      <p>
      Unsere Website wird nicht von uns selbst gehostet, sondern bei einem Dienstleister, der für den Zweck der die zuvor genannten Daten in unserem Auftrag gem. Art. 28 DSGVO verarbeitet.
      </p>
      <p>
      Zweck und Rechtsgrundlage
      </p>
      <p>
        Die Verarbeitung erfolgt zur Wahrung unseres überwiegenden berechtigten Interesses zur Anzeige unserer Website und Gewährleistung der Sicherheit und Stabilität auf Grundlage des Art. 6 Abs. lit. f DSGVO. Die Erfassung der Daten und die Speicherung in Logfiles ist für den Betrieb der Website zwingend erforderlich. Ein Widerspruchsrecht gegen die Verarbeitung besteht aufgrund der Ausnahme nach Art. 21 Abs. 1 DSGVO nicht. Soweit die weitergehende Speicherung der Logfiles gesetzlich vorgeschrieben ist, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1 lit. c DSGVO. Es besteht keine gesetzliche oder vertragliche Pflicht zur Bereitstellung der Daten, allerdings ist der Aufruf unserer Website ohne Bereitstellung der Daten technisch nicht möglich.
      </p>
      <p>
      Speicherdauer
      </p>
      <p>
        Die vorgenannten Daten werden für die Dauer der Anzeige der Website sowie aus technischen Gründen darüber hinaus für maximal 28 Tage gespeichert.
      </p>
      <p>
      Kontaktformular
      </p>
      <p>
      Art und Umfang der Verarbeitung
      </p>
      <p>
        Auf unserer Webseite bieten wir Ihnen an, über ein bereitgestelltes Formular mit uns in Kontakt zu treten. Die Informationen, die über Pflichtfelder erhoben werden, sind erforderlich um die Anfrage bearbeiten zu können. Darüber hinaus können Sie freiwillig zusätzliche Informationen bereitstellen, die aus Ihrer Sicht für die Bearbeitung der Kontaktanfrage notwendig sind.
      </p>
      <p>
      Bei der Nutzung des Kontaktformulars erfolgt keine Weitergabe Ihrer personenbezogenen Daten an Dritte.
      </p>
      <p>
      Zweck und Rechtsgrundlage
      </p>
      <p>
        Die Verarbeitung Ihrer Daten durch Nutzung unseres Kontaktformulars erfolgt zum Zweck der Kommunikation und Bearbeitung Ihrer Anfrage auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO. Soweit sich Ihre Anfrage auf ein bestehendes Vertragsverhältnis mit uns bezieht, erfolgt die Verarbeitung zum Zweck der Vertragserfüllung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Es besteht keine gesetzliche oder vertragliche Pflicht zur Bereitstellung Ihrer Daten, jedoch ist die Bearbeitung Ihrer Anfrage ohne die Bereitstellung der Informationen der Pflichtfelder nicht möglich. Soweit Sie diese Daten nicht bereitstellen möchten, kontaktieren Sie uns bitte mit anderen Mitteln.
      </p>
      <p>
      Speicherdauer
      </p>
      <p>
      Soweit Sie das Kontaktformular auf Grundlage Ihrer Einwilligung verwenden, speichern wir die erhobenen Daten jeder Anfrage für die Dauer von drei Jahren, beginnend mit der Erledigung Ihrer Anfrage oder bis zum Widerruf Ihrer Einwilligung.
      </p>
      <p>
      Google Fonts
      </p>
      <p>
      Art und Umfang der Verarbeitung
      </p>
      <p>
      Wir verwenden Google Fonts von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, als Dienst zur Bereitstellung von Schriftarten für unser Onlineangebot. Um diese Schriftarten zu beziehen, stellen Sie eine Verbindung zu Servern von Google Ireland Limited her, wobei Ihre IP-Adresse übertragen wird.
      </p>
      <p>
      Zweck und Rechtsgrundlage
      </p>
      <p>
      Der Einsatz von Google Fonts erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.
      </p>
      <p>
      Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. Die Datenübermittlung in die USA erfolgt nach Art. 45 Abs. 1 DSGVO auf Grundlage des Angemessenheitsbeschluss der Europäischen Kommission. Die beteiligten US-Unternehmen und/oder deren US-Unterauftragnehmer sind nach dem EU-U.S. Data Privacy Framework (EU-U.S. DPF) zertifiziert.
      </p>
      <p>
      In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (einschließlich der US-Unternehmen, die nicht nach EU-U.S. DPF zertifiziert sind) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind - sofern nicht anders angegeben - Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE [https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE] einsehen.
      </p>
      <p>
      Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.
      </p>
      <p>
      Speicherdauer
      </p>
      <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Google Ireland Limited bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Google Fonts: https://policies.google.com/privacy.
      </p>
      <p>
      JSDelivr CDN
      </p>
      <p>
      Art und Umfang der Verarbeitung
      </p>
      <p>
      Wir verwenden zur ordnungsgemäßen Bereitstellung der Inhalte unserer Website JSDelivr CDN. JSDelivr CDN ist ein Dienst der Prospect One, welcher auf unserer Website als Content Delivery Network (CDN) fungiert.
      </p>
      <p>
      Ein CDN trägt dazu bei, Inhalte unseres Onlineangebotes, insbesondere Dateien wie Grafiken oder Skripte, mit Hilfe regional oder international verteilter Server schneller bereitzustellen. Wenn Sie auf diese Inhalte zugreifen, stellen Sie eine Verbindung zu Servern der Prospect One, Krolewska 65a, Krakow, Malopolskie 30-081, Poland her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr User-Agent übermittelt werden. Diese Daten werden ausschließlich zu den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit und Funktionalität von JSDelivr CDN verarbeitet.
      </p>
      <p>
      Zweck und Rechtsgrundlage
      </p>
      <p>
      Die Nutzung des Content Delivery Networks erfolgt auf Grundlage unserer berechtigten Interessen, d.h. Interesse an einer sicheren und effizienten Bereitstellung sowie der Optimierung unseres Onlineangebotes gemäß Art. 6 Abs. 1 lit. f. DSGVO.
      </p>
      <p>
      Wir beabsichtigen personenbezogenen Daten an Drittländer außerhalb des Europäischen Wirtschaftsraums, insbesondere die USA, zu übermitteln. Die Datenübermittlung in die USA erfolgt nach Art. 45 Abs. 1 DSGVO auf Grundlage des Angemessenheitsbeschluss der Europäischen Kommission. Die beteiligten US-Unternehmen und/oder deren US-Unterauftragnehmer sind nach dem EU-U.S. Data Privacy Framework (EU-U.S. DPF) zertifiziert.
      </p>
      <p>
      In Fällen, in denen kein Angemessenheitsbeschluss der Europäischen Kommission existiert (einschließlich der US-Unternehmen, die nicht nach EU-U.S. DPF zertifiziert sind) haben wir mit den Empfängern der Daten anderweitige geeignete Garantien im Sinne der Art. 44 ff. DSGVO vereinbart. Dies sind - sofern nicht anders angegeben - Standardvertragsklauseln der EU-Kommission gemäß Durchführungsbeschluss (EU) 2021/914 vom 4. Juni 2021. Eine Kopie dieser Standardvertragsklauseln können Sie unter https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE [https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE] einsehen.
      </p>
      <p>
      Zudem holen wir vor einem solchen Drittlandtransfer Ihre Einwilligung nach Art. 49 Abs. 1 Satz 1 lit. a. DSGVO ein, die Sie über die Einwilligung im Consent Manager (oder sonstigen Formularen, Registrierungen etc.) erteilen. Wir weisen Sie darauf hin, dass bei Drittlandübermittlungen im Detail unbekannte Risiken (z.B. die Datenverarbeitung durch Sicherheitsbehörden des Drittlandes, deren genauer Umfang und deren Folgen für Sie wir nicht kennen, auf die wir keinen Einfluss haben und von denen Sie unter Umständen keine Kenntnis erlangen) bestehen können.
      </p>
      <p>
      Speicherdauer
      </p>
      <p>
        Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Prospect One bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für JSDelivr CDN: https://www.jsdelivr.com/privacy-policy-jsdelivr-net.
      </p>
      <p>
      Mapbox Maps
      </p>
      <p>
      Art und Umfang der Verarbeitung
      </p>
      <p>
      Wir verwenden zur Erstellung von Anfahrtsbeschreibungen den Kartendienst Mapbox. Mapbox ist ein Dienst der Mapbox, Inc., welcher auf unserer Website eine Karte darstellt.
      </p>
      <p>
      Wenn Sie auf diese Inhalte unserer Website zugreifen, stellen Sie eine Verbindung zu Servern der Mapbox, Inc., Washington, District of Columbia, United States her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr User-Agent übermittelt werden. Diese Daten werden ausschließlich zu den oben genannten Zwecken und zur Aufrechterhaltung der Sicherheit und Funktionalität von Mapbox verarbeitet.
      </p>
      <p>
      Zweck und Rechtsgrundlage
      </p>
      <p>
      Der Einsatz von Mapbox erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a. DSGVO und § 25 Abs. 1 TTDSG.
      </p>
      <p>
      Speicherdauer
      </p>
      <p>
      Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Mapbox, Inc. bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für Mapbox: https://www.mapbox.com/legal/privacy/.
      </p>
      <br>
      <a href="/" style="background-color:#237AFD; color: white !important; padding: 10px 15px; border-radius: 10px;">Zurück zur Portalpreise-Karte</a>
    <br><br><br><br><br><br>
<footer style="flex: 0; background-color: #FFF; margin-top: auto; bottom: 0; left: 0; right: 0; z-index: 1000; color:#666; position: fixed;">
  <v-container>
    <p style="margin-bottom: 0; font-size: 14px;">Ⓒ Makler Service AG {{ new Date().getFullYear() }} | <a href="
/">Startseite</a>| <a href="/impressum/">Impressum</a> | <a href="
/datenschutzerklaerung/">Datenschutz</a></p>
  </v-container>
</footer>
    </v-container>
  </div>
</template>

  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  
  <style scoped>
  p {
    font-weight: normal;
  }
  </style>
