import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('div',{staticClass:"centeredcontent"},[_c('h1',{staticClass:"logo logo-text text-center"},[_vm._v("XPansion")]),_c(VCard,{staticClass:"component loginbox"},[_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Password","type":"password","rules":[function () { return !!_vm.password || 'This field is required'; }]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":!_vm.isFormValid,"loading":_vm.loading},on:{"click":_vm.login}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account-arrow-left")]),_vm._v("Login")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }