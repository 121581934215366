import Vue from 'vue'
import VueRouter from 'vue-router'
import MapView from '../views/map/MapView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import ImprintPage from '../views/ImprintPage.vue'

Vue.use(VueRouter)

/**
 * Renders views and components via routing
 */
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: MapView
  },
  {
    path: '/datenschutzerklaerung', // Add the new route
    name: 'Datenschutz',
    component: PrivacyPolicy
  },
  {
    path: '/impressum', // Add the new route
    name: 'Impressum',
    component: ImprintPage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
