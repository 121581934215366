<!-- src/views/Datenschutz.vue -->
<template>
    <div>
        <header class="app navigation" style="flex: 0; background-color: var(--msag-color); padding: 10px; position: fixed; width: 100%">
        <v-container>
          <v-row justify="space-between" style="align-items: center; padding-bottom: 5px;">
            <div style="margin-right:10px;">
              <a href="/">
                <v-row justify="space-between" style="align-items: center;">
                  <v-col style="flex:0;">
                    <div style="background-color:white; padding: 10px; border-radius: 10px; height: 50px; width: 50px;">
                      <img src="@/assets/Maklersprechstunde-Logo.png" alt="Square Image" style="width: 30px; height: 30px;"/>
                    </div>
                  </v-col>
                  <v-col style="flex:1; padding: 0px; margin: 0px;">
                    <h1 style="color: white; text-align: left;">Portalpreise.com</h1>
                  </v-col>
                </v-row>
              </a>
            </div>
          </v-row>
        </v-container>
      </header>
  
      <v-container>
        <h1 style="margin-top: 100px;">Impressum</h1>
       <p> 
        <b>Makler Service AG</b>
        <br>
        Baarerstrasse 133
        <br>
        6300 Zug
        </p>
    <p>
    E-Mail: office@maklersprechstunde.com
    <br>
    Internet: www.immobilien-makler-akademie.com
    <br>
    Tel: +41 415520292
    </p>
    <p>
    <b>Vertretungsberechtigte Personen:</b>
    <br>
    – Verwaltungsrat Georg Ortner
    <br>
    – Verwaltungsrat Roland Beeler
    </p>
    <p>
    <b>Handelsregistereintrag:</b>
    <br>
    Registergericht: Handelsregister des Kanton Zug
    <br>
    UID (Unternehmensidentifikationsnummer): CHE-491.523.825
    <br>
    Mehrwertsteuernummer CHE-491.523.825 MWST
    </p>
    <p>
    Verantwortlich für den Inhalt: Georg Ortner
    </p>
    <p>
    Dieses Impressum gilt für die Webseite maklersprechstunde.com und ist auch gültig für den Auftritt der Maklersprechstunde im Web und auf Facebook sowie für die Seite www.portalpreise.com, die von uns betrieben wird. 
    </p>
    <p>
    <b>Hinweis auf EU-Streitschlichtung:</b>
    </p>
    <p>
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Diese Plattform finden Sie unterhttps://ec.europa.eu/consumers/odr/ 
    Wir sind nicht verpflichtet und bereit, an einem Streitbeilegungsverfahren teilzunehmen.
    </p>
    <p>
    Von Fachtraining bis zum Verkaufstraining, von juristischen Standpunkten hin zu Management Themen werden hier über eine Vielzahl von Trainern und echten Branchen Experten Themen, einfach und effizient, über das Medium Video erklärt.
    </p>
    <br>
    <a href="/" style="background-color:#237AFD; color: white !important; padding: 10px 15px; border-radius: 10px;">Zurück zur Portalpreise-Karte</a>
    <br><br><br><br><br><br>
    <footer style="flex: 0; background-color: #FFF; margin-top: auto; bottom: 0; left: 0; right: 0; z-index: 1000; color:#666; position: fixed;">
    <v-container>
      <p style="margin-bottom: 0; font-size: 14px;">Ⓒ Makler Service AG {{ new Date().getFullYear() }} | <a href="
  /">Startseite</a>| <a href="/impressum/">Impressum</a> | <a href="
  /datenschutzerklaerung/">Datenschutz</a></p>
    </v-container>
  </footer>
      </v-container>
    </div>
  </template>
  
    <script>
    export default {
      name: 'ImprintPage'
    }
    </script>
    
    <style scoped>
    p {
      font-weight: normal;
    }
    </style>
  