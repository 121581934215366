<template>
  <div class="component sidebar padding-bottom">
    <v-container class="component sidebar-container">
      <v-row>
        <v-col class="tight">
          <h2 class="title-text text-left">Stadt Informationen
            <v-btn icon @click="closeSidebar" class="close-button">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </h2>          
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-left">
          <h3 class="secondary-text text-left">Portalpreis / Monat</h3>
        </v-col>
        <v-col>
          <h3 class="sub-text text-right highlight-big">∅ {{ averagePrice }}€</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="tight col-left">
          <h3 class="secondary-text text-left">Postleitzahl</h3>
        </v-col>
        <v-col class="tight">
          <h3 class="text text-right">{{ zipcodePart }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="tight col-left">
          <h3 class="secondary-text text-left">Stadt</h3>
        </v-col>
        <v-col class="tight">
          <h3 class="text text-right">{{ cityName }}</h3>
        </v-col>
      </v-row>
      <!--
        <v-row>
        <v-col class="tight col-left">
          <h3 class="secondary-text text-left">Bezirksname</h3>
        </v-col>
        <v-col class="tight">
          <h3 class="text text-right">{{ localObj.district }}</h3>
        </v-col>
      </v-row>
      -->
      <v-row>
        <v-col class="tight col-left">
          <h3 class="secondary-text text-left">Bundesstaat</h3>
        </v-col>
        <v-col class="tight">
          <h3 class="text text-right">{{ stateName }}</h3>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <v-col>
          <v-row>
            <v-col class="tight-heading">
              <h2 class="title-text text-left">Preis-Einträge</h2>
            </v-col>
          </v-row>
          <v-row v-for="price in localObj.prices" :key="price.id">
            <v-col>
                <v-row>
                  <v-col class="tight-heading">
                    <h2 class="title-text-secondary text-left">
                      {{ price.company_name }}
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="tight">
                    <span class="sub-text text-left">{{ price.profession }}</span><br>
                    <span class="text-normal sub-text text-left">{{ price.address }}</span><br>
                    <a :href="price.company_url" class="text-normal sub-text text-left">{{ price.company_url }}</a><br>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="tight">
                    <span class="sub-text text-left">Preisdaten:</span><br>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col class="tight">
                    <h3 class="secondary-text text-left">Datum</h3>
                  </v-col>
                  <v-col class="tight">
                    <h3 class="sub-text text-left">
                      {{ new Date(price.created_at["$date"]).toLocaleDateString('de-DE') }}
                    </h3>
                  </v-col> 
                </v-row>
                <v-row justify="space-between">
                  <v-col class="tight">
                    <h3 class="secondary-text text-left">Portal</h3>
                  </v-col>
                  <v-col class="tight">
                    <h3 class="sub-text text-left">
                      {{ price.portal }}
                    </h3>
                  </v-col> 
                </v-row>
                <v-row justify="space-between">
                  <v-col class="tight">
                    <h3 class="secondary-text text-left">Preis / Monat</h3>
                  </v-col>
                  <v-col class="tight">
                    <h3 class="sub-text text-left highlight">
                      {{ price.price_per_month }}€
                    </h3>
                  </v-col> 
                </v-row>
                <v-row justify="space-between">
                  <v-col class="tight">
                    <h3 class="secondary-text text-left">Objekte / Monat</h3>
                  </v-col>
                  <v-col class="tight">
                    <h3 class="sub-text text-left">
                      {{ price.objects_per_month }}
                    </h3>
                  </v-col> 
                </v-row>
                <v-row justify="space-between">
                  <v-col class="tight">
                    <h3 class="secondary-text text-left">Vertragszusätze</h3>
                  </v-col>
                  <v-col class="tight">
                    <h3 class="sub-text text-left">
                      <template v-if="price.contract_additions">Ja</template>
                      <template v-else>Nein</template>
                    </h3>
                  </v-col> 
                </v-row>                  
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      -->
    </v-container>
    <v-dialog v-model="confirmDialog" max-width="50vmin">
      <ConfirmDialog
        v-if="confirmDialog"
        @submitted="confirmDelSubmitted"
        @cancelled="confirmDialog = false"
        title="Delete Contract"
        desc="Are you sure you want to delete this contract? This action is irreversible."
        confirm="Delete"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { postToServer } from "@/scripts/serverCalls";
import ConfirmDialog from "./dialogs/ConfirmDialog.vue";

export default {
  name: "SideBar",

  components: {
    ConfirmDialog,
  },
  props: ["obj"],
  watch: {
    obj: {
      // Sorts History on update
      immediate: true,
      handler(newValue) {
        //console.log(newValue);
        this.localObj = newValue;
        this.sortHistoryByDate();
      },
    },
  },
  data() {
    return {
      confirmDialog: false,
      localObj: null,
      toDelete: null,
    };
  },
  computed: {
    zipcodePart() {
      return this.localObj ? this.localObj.zipcode?.split('-')[1] : null;
    },
    stateName() {
      if (!this.localObj) return null;
      const code = this.localObj.zipcode?.split('-')[0];
      switch (code) {
        case "AT":
          return "Österreich";
        case "CH":
          return "Schweiz";
        case "DE":
          return "Deutschland";
        default:
          return "";
      }
    },
    averagePrice() {
      if (!this.localObj) return null;
      return this.localObj.average_price_per_month?.toLocaleString('de-DE', { maximumFractionDigits: 0 });
    },
    cityName() {
      return this.localObj.city || this.localObj.district;
    },
  },
  methods: {
    /**
     * Reformats date objects to DD.MM.YYYY
     * @param {*} str Date string
     */
    formatDateString(str) {
      const date = new Date(str);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    closeSidebar() {
      this.$emit('close-sidebar');
    },
    sortHistoryByDate() {
      if (this.localObj && this.localObj.history) {
        this.localObj.history = this.localObj.history.sort(function (a, b) {
          return new Date(b.to) - new Date(a.to);
        });
      }
    },
    openDelDialog(areaIds, contractId) {
      this.toDelete = { areaIds: areaIds, contractId: contractId };
      this.confirmDialog = true;
    },
    confirmDelSubmitted() {
      this.deleteContract();
      this.confirmDialog = false;
    },
    unlinkFromInterested() {
      this.$emit("unlinkFromInterested");
    },
    unlinkFromContract(contractId) {
      this.$emit("unlinkFromContract", contractId);
    },
    deleteContract() {
      postToServer(
        "map/unlinkfromcontract",
        {
          areaIds: this.toDelete.areaIds,
          contractId: this.toDelete.contractId,
        },
        (res) => {
          this.$emit("update");
        }
      );
    },
    markAllInInterested() {
      postToServer(
        "map/fetchzipcodeswithid",
        {
          areaIds: this.localObj.interested[0].areaId,
        },
        (res) => {
          this.$emit("markAllZipcodes", res.data);
        },
        true
      );
    },
    markAllInContract(contract) {
      postToServer(
        "map/fetchzipcodeswithid",
        {
          areaIds: contract.areaId,
        },
        (res) => {
          this.$emit("markAllZipcodes", res.data);
        },
        true
      );
    },
  },
};
</script>
<style lang="scss">
.component.sidebar {
  position: fixed;
  background-color: var(--background-color);
  z-index: 100;
  /* width: calc(6rem + 20vw + 7.5vh);*/
  left: 0;
  padding: 10px;
 /* padding-left: calc(0.5rem + 0.35vw + 0.5vh);
  padding-right: calc(0.75rem + 0.75vw + 0.7vh);
  padding-top: calc(0.5rem + 0.35vw + 0.5vh); */
  overflow-y: auto;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 80px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.col-left {
    flex-grow: 0 !important;
    white-space: nowrap;
}

@media screen and (min-width: 640px) {
.component.sidebar {
  margin-top: 66px;
}
}
</style>
