<template>
    <v-card class="component dialog">
      <v-card-title>
        <h2 class="title-text text-left">{{ title }}</h2>
      </v-card-title>
      <v-card-text>
          <span class="text text-left">{{ desc }}</span>
      </v-card-text>
      <v-card-actions>
          <v-btn color="success" @click="submit()">{{ confirm }}</v-btn>
          <v-btn color="error" @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </template>

  <script>
  export default {
    name: "ConfirmDialog",
  
    methods:{
      /**
       * Submits dialog and returns duration object
       */
      submit(){
          this.$emit("submitted");
      },
      cancel(){
        this.$emit("cancelled");
      }
    },
    props: ["title", "desc", "confirm"]
  };
  </script>
  <style lang="scss">
  .component.dialog{
      padding:calc(0.25rem + 0.5vw + 0.5vh);
  }
  </style>