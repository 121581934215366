<template>
  <div class="view">
    <div class="centeredcontent">
      <h1 class="logo logo-text text-center">XPansion</h1>
      <v-card class="component loginbox">
        <v-card-text>
          <v-form v-model="isFormValid" @submit.prevent>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[() => !!password || 'This field is required']"
                  @keyup.enter="login()"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!isFormValid" @click="login" :loading="loading"
            ><v-icon left>mdi-account-arrow-left</v-icon>Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { getFromServer } from "@/scripts/serverCalls";

export default {
  name: "LoginView",

  data() {
    return {
      password: null,
      loading: false,
    };
  },
  methods: {
    login() {
      if (this.password != null && this.password != "") {
        this.loading = true;
        getFromServer(
          `map/authorize?password=${this.password}`,
          (res) => {
            this.$emit("loginAttempt", res.data);
            this.loading = false;
          }
        );
      } else {
        window.globalToast.info("Empty Password");
        this.loading = false;
    }
    },
  },
  created(){
    document.title = `Portal Prices - Login`;
  }
};
</script>
<style lang="scss">
.component.loginbox {
  padding: calc(0.25rem + 0.5vw + 0.5vh);
}
.centeredcontent {
  position: absolute;
  width: 100%;
  padding-left: calc(2rem + 35vw - 7vh);
  padding-right: calc(2rem + 35vw - 7vh);
  top: 50%;
  transform: translateY(-50%);
}
.logo {
  margin-bottom: calc(1rem + 0.5vw + 0.5vh);
}
.logo-text {
  font-weight: 100;
  font-size: calc(5rem + 2vw);
  font-family: "OpenSans-Light", "Segoe UI", sans-serif;
}
</style>
