import axios from "axios";

// Checks where to connect
function isLocalIpAddress(hostname) {
  return hostname === 'localhost' ||
         hostname.startsWith('127.') || // Loopback
         /^192\.168\./.test(hostname) || // Private network IP
         /^10\./.test(hostname) ||
         /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(hostname);
}

// Determines API url
const baseURL = isLocalIpAddress(window.location.hostname) 
  ? process.env.VUE_APP_API_ADDRESS_DEV 
  : process.env.VUE_APP_API_ADDRESS_PROD;

export async function postToServer(url, obj, _callback) {
  await axios({
    url: `${baseURL}/${process.env.VUE_APP_MAIN_SERVER_KEY}/${url}`, // Server Addresses can be changed globally in .env file
    method: "post",
    data: obj,
  })
    .then((res) => {
      _callback(res);
    })
    .catch((err) => {
      if(isLocalIpAddress(window.location.hostname)) console.log(err);  // Logs when environment is dev
      _callback(err);
    });
}

export async function getFromServer(url, _callback) {
  await axios({
    url: `${baseURL}/${process.env.VUE_APP_MAIN_SERVER_KEY}/${url}`,
    method: "get",
  })
    .then((res) => {
      _callback(res);
    })
    .catch((err) => {
      if(isLocalIpAddress(window.location.hostname)) console.log(err);  // Logs when environment is dev
      _callback(err);
    });
}