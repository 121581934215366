<template>
  <v-app class="app">
    <v-main>
      <LoginView v-if="!isAuthorized" @loginAttempt="login"/>
      <!-- Renders views via routing, check ./router/index.js -->
      <router-view v-else class="app main" />
    </v-main>
  </v-app>
</template>

<script>
import LoginView from './views/LoginView.vue';

export default {
  name: "App",

  components: {
    LoginView
  },
  data: () => ({
    isAuthorized: true,
  }),
  methods: {
    login(e){
      if(e) this.isAuthorized = true;
      else window.globalToast.error("Wrong Password");
    }
  }
};
</script>
<style lang="scss">
@import "./appStyling.css";
</style>
