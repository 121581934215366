import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component sidebar padding-bottom"},[_c(VContainer,{staticClass:"component sidebar-container"},[_c(VRow,[_c(VCol,{staticClass:"tight"},[_c('h2',{staticClass:"title-text text-left"},[_vm._v("Stadt Informationen "),_c(VBtn,{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.closeSidebar}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)])],1),_c(VRow,[_c(VCol,{staticClass:"col-left"},[_c('h3',{staticClass:"secondary-text text-left"},[_vm._v("Portalpreis / Monat")])]),_c(VCol,[_c('h3',{staticClass:"sub-text text-right highlight-big"},[_vm._v("∅ "+_vm._s(_vm.averagePrice)+"€")])])],1),_c(VRow,[_c(VCol,{staticClass:"tight col-left"},[_c('h3',{staticClass:"secondary-text text-left"},[_vm._v("Postleitzahl")])]),_c(VCol,{staticClass:"tight"},[_c('h3',{staticClass:"text text-right"},[_vm._v(_vm._s(_vm.zipcodePart))])])],1),_c(VRow,[_c(VCol,{staticClass:"tight col-left"},[_c('h3',{staticClass:"secondary-text text-left"},[_vm._v("Stadt")])]),_c(VCol,{staticClass:"tight"},[_c('h3',{staticClass:"text text-right"},[_vm._v(_vm._s(_vm.cityName))])])],1),_c(VRow,[_c(VCol,{staticClass:"tight col-left"},[_c('h3',{staticClass:"secondary-text text-left"},[_vm._v("Bundesstaat")])]),_c(VCol,{staticClass:"tight"},[_c('h3',{staticClass:"text text-right"},[_vm._v(_vm._s(_vm.stateName))])])],1)],1),_c(VDialog,{attrs:{"max-width":"50vmin"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[(_vm.confirmDialog)?_c('ConfirmDialog',{attrs:{"title":"Delete Contract","desc":"Are you sure you want to delete this contract? This action is irreversible.","confirm":"Delete"},on:{"submitted":_vm.confirmDelSubmitted,"cancelled":function($event){_vm.confirmDialog = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }